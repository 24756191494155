/*eslint-env jquery*/
(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('ServiceProvidersRelatedCompController', ServiceProvidersRelatedCompController);

    ServiceProvidersRelatedCompController.$inject = ['$uibModalInstance', 'previousState', '$http'];


    function ServiceProvidersRelatedCompController($uibModalInstance, previousState, $http) {
        var vm = this;

        //vm.serviceProviders = entity;
        vm.spid = previousState.spid;
        vm.clear = clear;
        vm.relatedCompanies = null;
        vm.relatedCompaniesParentId = null;
        vm.relatedCompaniesParentLegalName = null;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.ifSPILMorISM = function(){

            if (!_.isUndefined(vm.relatedCompanies.memberAssociations))
                for (var i = 0; i < vm.relatedCompanies.memberAssociations.length; i++) {
                    var memberType = vm.relatedCompanies.memberAssociations[i].memberType;
                    if (memberType &&
                        (memberType.id === 1660 || memberType.id === 1661 || memberType.id === 1676 || memberType.id === 1678
                            || memberType.id === 1679 || memberType.id === 5555 || memberType.id === 5556 || memberType.id === 5557)) {
                        return true;
                    }
                }
            return false;
        };

        getRelatedProviders();

        function getRelatedProviders() {
            var response = $http.get('api/_search/related-service-providers?sort=serviceproviderAddresses.country,asc', {
                params: {
                    id: vm.spid,
                    query: 'q= ',
                    sensor: false
                }
            }).then(function (response) {
                vm.noresults = false;
                vm.srd = response.data;
                vm.relatedCompanies = response.data;
                $.each
                (
                    vm.relatedCompanies,
                    function (index, relatedCompany) {
                        if (relatedCompany) {
                            $.each
                            (
                                relatedCompany.serviceproviderAddresses,
                                function (index, serviceproviderAddress) {
                                    if (serviceproviderAddress.addressType.id === 1530) {
                                        relatedCompany.mainServiceproviderAddress = serviceproviderAddress;
                                        return false;
                                    }
                                }
                            );

                            console.log('Check for related companies parent');
                            if (relatedCompany.id === relatedCompany.serviceProvidersId) {
                                vm.relatedCompaniesParentId = relatedCompany.id;
                                vm.relatedCompaniesParentLegalName = relatedCompany.companylegalname;
                                console.log('Related companies parent id: ' + vm.relatedCompaniesParentId + ', parent legal name: ' + vm.relatedCompaniesParentLegalName);
                            }
                        }
                    }
                )
                ;
            });


        }



    }
})();
